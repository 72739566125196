<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="item.label"
                            :label="trans('fields.common.label')"
                            :error-messages="errors.label"
                            @input="clearError('label')"
                        ></v-text-field>
                        <v-autocomplete
                            v-if="!offer_id"
                            :items="options.offers"
                            v-model="item.offer_id"
                            :label="trans('fields.common.offer')"
                            :error-messages="errors.offer_id"
                        ></v-autocomplete>
                        <v-autocomplete
                            v-if="options && options.foreignOffers"
                            :items="options.foreignOffers"
                            v-model="item.foreign_offer_id"
                            :label="trans('fields.common.foreignOffer')"
                            :error-messages="errors.foreign_offer_id"
                        ></v-autocomplete>
                        <v-autocomplete
                            v-if="options && options.apiLinkLandings"
                            :items="options.apiLinkLandings"
                            v-model="item.link_index"
                            :label="trans('fields.common.landingPage')"
                            :error-messages="errors.link_index"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="item.id" color="primary" outlined @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="primary" outlined @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "LinkVendorLockForm",
        mixins: [formValidate],
        components: {
        },
        props: {
            itemId: {
                type: [Number, undefined],
            },
            parentItem: {
                type: [Object, undefined],
            },
            cpa_network_id: {
                default: null,
            },
            offer_id: {
                default: null,
            },
            parentOptions: {
                type: [Object, Array, undefined],
            },
        },
        data(){
            return {
                showRoute: 'user.links.show',
                storeRoute: 'user.links.storeApi',
                updateRoute: 'user.links.update',
                item: {
                    id: null,
                    label: '',
                    offer_id: this.offer_id,
                    foreign_offer_id: null,
                    cpa_network_id: this.$store.getters.vendorId ? this.$store.getters.vendorId : this.cpa_network_id,
                    link_index: null,
                },
                options: {},
                cpaNetworkDialog: false,
                cpaNetworkDialogLoaded: false,
            }
        },
        created() {
            this.load();
            if (this.offer_id){
                this.loadForeignOffers();
            }
        },
        watch: {
            "item.offer_id"(){
                this.item.foreign_offer_id = null;
                this.loadForeignOffers();
            },
            "item.foreign_offer_id"(){
                this.item.link_index = null;
                this.loadApiLinkLandings();
            },
        },
        methods: {
            load(){
                if (this.parentItem){
                    this.item = this.parentItem;
                    this.loadOptions();
                } else if(this.itemId) {
                    axios.get(this.route(this.showRoute, this.itemId)).then(response => {
                        this.item = response.data;
                        this.loadOptions();
                    }).catch(err => {
                        console.log(err);
                    })
                } else {
                    this.loadOptions();
                }
            },
            loadOptions(){
                if (!this.offer_id){
                    axios.get(this.route('user.options'), {params: {
                            offers: true,
                        }}).then( response => {
                        this.options = {
                            ...this.parentOptions,
                            ...response.data
                        };
                        this.loadForeignOffers();

                    })
                }
            },
            loadForeignOffers(){
                axios.post(this.route('user.options'), {
                        foreignOffers: {offer_id: this.item.offer_id, cpa_network_id: this.item.cpa_network_id},
                    }).then( response => {
                    this.options = {
                        ...this.options,
                        ...response.data
                    };
                    if (this.options.foreignOffers.length > 0){
                        this.item.foreign_offer_id = this.options.foreignOffers[0].value;
                        this.loadApiLinkLandings();

                    }
                })
            },
            loadApiLinkLandings(){
                axios.post(this.route('user.options'), {
                        apiLinkLandings: {foreign_offer_id: this.item.foreign_offer_id},
                    }).then( response => {
                    this.options = {
                        ...this.options,
                        ...response.data
                    };
                    if (this.options.apiLinkLandings.length > 0){
                        this.item.link_index = 0;
                    }
                })
            },

            save() {
                if (!this.item.id) {
                    axios.post(this.route(this.storeRoute), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                } else {
                    axios.post(this.route(this.updateRoute, this.item.id), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                }
            },

        },
        computed: {
            formTitle() {
                return !this.item.id
                    ? this.trans('pages.link.newDialogTitle')
                    : this.trans('pages.link.editDialogTitle');
            },

        }
    }
</script>
