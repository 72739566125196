<template>
    <v-card>
        <v-card-title>
            <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.link.newItem') }}</v-btn>
                </template>
                <template v-if="$store.getters.vendorId">
                    <v-tabs
                            v-if="$store.getters.user.userCpaIntegrations[$store.getters.vendorId]"
                            background-color="primary"
                            dark
                    >
                        <v-tab>
                            {{trans('pages.link.form.byApi')}}
                        </v-tab>
                        <v-tab>
                            {{trans('pages.link.form.manual')}}
                        </v-tab>
                        <v-tab-item>
                            <link-vendor-lock-form
                                    v-if="dialogLoaded"
                                    :item-id="editedItem"
                                    v-on:saved="onSave"
                                    v-on:close="close"
                            />
                        </v-tab-item>
                        <v-tab-item>
                            <link-vendor-lock-with-input-form
                                    v-if="dialogLoaded"
                                    :item-id="editedItem"
                                    :parent-options="options"
                                    v-on:saved="onSave"
                                    v-on:close="close"
                            />
                        </v-tab-item>
                    </v-tabs>
                </template>
                <template v-else>
                    <link-form v-if="dialogLoaded" :item-id="editedItem" v-on:saved="onSave" v-on:close="close"></link-form>
                </template>
            </v-dialog>
            <delete-dialog
                :dialogTrigger="deleteDialog"
                :item-id="deleteItemId"
                :delete-route="deleteRoute"
                @closed="deleteDialogClosed"
                @deleted="deleteDialogDeleted"
            >
                <template v-slot:title>
                    {{ trans('pages.link.deleteDialogTitle') }}
                </template>
                <template v-slot:text>
                    {{ trans('pages.link.deleteDialogText', {id: deleteItemText ? '"'+deleteItemText+'"' : deleteItemId}) }}
                </template>
            </delete-dialog>
            <v-spacer></v-spacer>
            <v-icon
                class="pull-right"
                color="primary"
                @click="filterShow = filterShow === 0 ? null : 0"
            >mdi-filter</v-icon>
            <v-expansion-panels
                v-model="filterShow"
                flat
                tile
            >
                <v-expansion-panel>
                    <v-expansion-panel-content class="pt-4">
                        <v-row>
                            <v-col style="min-width: 200px;">
                                <v-text-field
                                    v-model="filters.label"
                                    :label="trans('fields.common.label')"
                                ></v-text-field>
                            </v-col>
                            <v-col style="min-width: 200px;">
                                <v-text-field
                                    v-model="filters.url"
                                    :label="trans('fields.common.url')"
                                ></v-text-field>
                            </v-col>
                            <v-col style="min-width: 200px;">
                                <v-text-field
                                    v-model="filters.offer_label"
                                    :label="trans('fields.common.offer')"
                                ></v-text-field>
                            </v-col>
                            <v-col style="min-width: 200px;">
                                <v-select
                                    :items="options.cpaNetworks"
                                    :label="trans('fields.common.cpaNetwork')"
                                    :multiple="true"
                                    v-model="filters.cpa_network_id"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-btn
                                class="ma-2"
                                color="primary"
                                outlined
                                @click="applyFilters"
                            >{{ trans('buttons.common.apply') }}</v-btn>
                            <v-btn
                                class="ma-2"
                                color="orange"
                                outlined
                                @click="clearFilters"
                            >{{ trans('buttons.common.clear') }}</v-btn>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="links.data"
            :items-per-page="paginationParams.per_page"
            :page="paginationParams.page"
            :server-items-length="links.total"
            :loading="loading"
            :footer-props="footerProps"
            sort-by="id"
            class="border"
            @update:sort-by="sortBy"
            @update:sort-desc="sortDesc"
            @update:page="changePage"
            @update:items-per-page="changeItemsPerPage"
        >
            <template v-slot:item.actions="{ item }">
                <v-icon v-if="$store.getters.vendorRestrictions && !$store.getters.vendorRestrictions.lockLinkEdit" small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon
                    small
                    :title="trans('buttons.common.delete')"
                    @click="deleteDialogShow(item.id, item.label)"
                >mdi-delete</v-icon>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | mysqlDate }}
            </template>
            <template v-slot:item.user="{ item }">
                <template v-if="item.user">{{ item.user.id }} :: {{ item.user.email }}</template>
            </template>
            <template v-slot:item.offer="{ item }">
                <template v-if="item.offer">{{ item.offer.label }}</template>
            </template>
            <template v-slot:item.cpaNetwork="{ item }">
                <template v-if="item.cpa_network">{{ item.cpa_network.label }}</template>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import DeleteDialog from "@/components/commonComponents/DeleteDialog";
    import deleteDialog from "@/mixins/deleteDialog";
    import tableFilters from "@/mixins/tableFilters";
    import LinkVendorLockForm from "@/components/user/link/LinkVendorLockForm";
    import LinkVendorLockWithInputForm from "@/components/user/link/LinkVendorLockWithInputForm";

    export default {
        name: "LinkTable",
        components: {
            LinkVendorLockWithInputForm,
            LinkVendorLockForm,
            LinkForm: () => import('@/components/user/link/LinkForm'),
            DeleteDialog,
        },
        mixins: [
            tableWithPagination,
            deleteDialog,
            tableFilters
        ],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                links: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.label"), value: "label", sortable: true },
                    this.$store.getters.vendorId ? {} : { text: this.trans("fields.common.cpaNetwork"), value: "cpaNetwork", sortable: false,  },
                    { text: this.trans("fields.common.offer"), value: "offer", sortable: false },
                    { text: this.trans("fields.common.url"), value: "url", sortable: false },
                    { text: this.trans("fields.common.created_at_f"), value: "created_at", sortable: true },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('user.links.index'),
                deleteRoute: 'user.links.delete',

                filters: {
                    label: '',
                    offer_label: '',
                    cpa_network_id: null,
                    url: '',
                },
                filtersDefault: {
                    label: '',
                    offer_label: '',
                    cpa_network_id: null,
                    url: '',
                },

                editedItem: null,
            }
        },
        created() {
            this.editedItem = null;
            this.loadOptions({cpaNetworks: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            onSave(){
                this.close();
                this.initialize();
            },
            editItem(item) {
                this.editedItem = item.id;
                this.dialog = true;
                this.dialogLoaded = true;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = null;
                    this.dialogLoaded = false;
                }, 300);
            },


        },
        computed: {
            paginationItems: {
                get(){
                    return this.links;
                },
                set(v){
                    this.links = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
